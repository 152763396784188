import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FilterMemoryService {
	public filtersArray: Map<string, any>;

	constructor() { }

	getFilters(screenName: string): Map<string, any> {
		return this.filtersArray ? this.filtersArray.get(screenName) : undefined;
	}

	setFilters(screenName: string, filters: Map<string, any>): void {
		if (!this.filtersArray) {
			this.filtersArray = new Map<string, any>();
		}

		this.filtersArray.set(screenName, filters);
	}

	retrieveMemoryFilters(route: string, mapFilter) {
		const memoryFilter = this.getFilters(route);
		if (memoryFilter) {
			let searchText = '';
			memoryFilter.forEach((value, key) => {
				if (key === 'searchText') {
					searchText = value;
				} else {
					if (mapFilter.has(key)) {
						mapFilter.set(key, value);
					}
				}
			});

			return { searchText, mapFilter };
		}

		return undefined;
	}

	checkMapFilterChanges(mapFilter) {
		if (mapFilter) {
			// tslint:disable-next-line: no-unused-variable
			for (const [key, value] of mapFilter) {
				if (value.arrayData.length > 0 || value.arrayValue.length > 0 || value.allSelected) {
					return true;
				}
			}
		}

		return false;
	}
}