import {hammerjsVersion} from "@angular/material/schematics/ng-add/version-names";

export const csvToJsonUtil = ((csv): unknown[] => {
	const lines = csv.split("\n");
	const result = [];
	if (lines[0] == 'sep=;\r') {
		lines.shift();
	}
	const headers = lines[0].split(";");
	for (let i = 1; i < lines.length; i++) {
		if (lines[i]) {
			const obj = {};
			const currentLine = lines[i].split(";")
			for (let j = 0; j < headers.length; j++) {
				let headerName = headers[j];
				if (headerName.length) {
					headerName = formatName(headerName.replace("\r", "").replace("%","").trim());
					if (headerName) {
						if (currentLine[j]) {
							obj[headerName] = currentLine[j].replace("\r", "");
						} else {
							obj[headerName] = "";
						}
					}
				}
			}
			result.push(obj);
		}
	}
	return result;
});

export const csvHeadersToJsonUtil = ((csv): any[] => {
	const lines = csv.split("\n");
	if (lines[0] == 'sep=;\r') {
		lines.shift();
	}
	return lines[0].split(";");
})

export const formatName = (name: string): string => {
	const words = name.split(' ');
	if(words.length == 1) {
		words[0] = words[0].toLowerCase();
	}
	const formattedName = words.map((word, index) => {
		if (index === 0) {
			return word.toLowerCase();
		}
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}).join('');
	return formattedName;
}