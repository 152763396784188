import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AbstractForm } from '../abstract/abstract-form';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class ExcelService extends AbstractForm {

	constructor() {
		super();
	}

	fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	fileExtension = '.xlsx';

	public exportExcel(jsonData: any[], fileName: string): void {
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_json(ws, jsonData, {origin:"A1"});


		const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		this.saveExcelFile(excelBuffer, fileName);
	}

	private saveExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], {type: this.fileType});
		FileSaver.saveAs(data, fileName + this.fileExtension);
	}

	public exportExcelWithoutHeader(jsonData: any[], fileName: string): void {
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_json(ws, jsonData, {origin:"A1"});

		const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		this.saveExcelFile(excelBuffer, fileName);
	}
}