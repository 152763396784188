import { isArray } from "util";

export const dataSelectionDeselectionManagement = (values, list, idProperty, index?) => {
    if(isArray(values)) {
			let currentList = [];
			if(index && list[index] == undefined) {
				list[index] = [];
			}
	    index || index == 0 ? currentList = list[index] : currentList = list;
        values.forEach(value => {
            value.checked ? selectedOptions(value, currentList, idProperty) : deselectOptions(value, currentList, idProperty);
        });
    }
}

export const selectedOptions = (event, list, idProperty) => {
	const index = list.findIndex(item => item[idProperty] === event[idProperty]);
	if (index === -1) {
		list.push(event);
	}
}

export const deselectOptions = (event, list, idProperty) => {
	const index = list.findIndex(item => item[idProperty] === event[idProperty]);
	if (index !== -1) {
		list.splice(index, 1);
	}
}

export const initializeArray = (list, size) => {
	if(list.length != size) {
		list = [{checked: true}];
		for(let i = 0; i < size; i++) {
			list.push([]);
		}
	}
}

export const nestedDropdownList = (
    options: any[],
    idProperty,
    parentProperty,
    situationProperty,
    findParentOptions: (option: any[], service: any, searchText: any) => Promise<any>,
    service: any,
    searchText: any
    ) => {
    return new Promise(function (resolve, reject) {
        let foundOptions;

        const optionsWithParentWhenSituationIsTrue = options.filter(option =>
            option[parentProperty]
            && option[parentProperty] != null
            && option[situationProperty]
        );

        const optionsWithoutParentWhenSituationIsTrue = options.filter(option =>
            !option[parentProperty]
            && option[situationProperty]
        );

        const optionsIdsWithParentWhenSituationIsTrue = optionsWithParentWhenSituationIsTrue.map(option =>
            option[idProperty]
        );

        const changeOptionSituation = (
            options: any[],
            optionsWithParentWhenSituationIsTrue: any[]
        ) => {
            options.forEach(option => {
                optionsWithParentWhenSituationIsTrue.forEach(optionWithAuxWhenSituationIsTrue => {
                    if (option[idProperty] == optionWithAuxWhenSituationIsTrue[parentProperty]
                        || option[idProperty] == optionWithAuxWhenSituationIsTrue[idProperty]) {
                        option[situationProperty] = true;
                    }
                });
                optionsWithoutParentWhenSituationIsTrue.forEach(optionWithAuxWhenSituationIsTrue => {
                    if (option[idProperty] == optionWithAuxWhenSituationIsTrue[idProperty]) {
                        option[situationProperty] = true;
                    }
                });
            });
        }

        findParentOptions(
            optionsIdsWithParentWhenSituationIsTrue,
            service,
            searchText
        ).then(result => {
            foundOptions = result.data;
            changeOptionSituation(foundOptions, optionsWithParentWhenSituationIsTrue);
            resolve(foundOptions);
        });
    });
}


