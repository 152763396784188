export const readFile = ((blob: Blob): Promise<string> => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result as string);
		};

		reader.onerror = () => {
			reject(reader.error);
		};

		reader.readAsText(blob, 'ISO-8859-1');
	});
})